<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';
import DateNormal from '@/components/DateCustoms/DateNormal/main.vue';
import PickerDate from '@/components/DateCustoms/PickerDate/main.vue';

import { masterComputed, masterMethods, clientMethods, campaignMethods, prizeMethods, authComputed } from '@/state/helpers';
import { checkPermission } from '@/utils/handlers';
import { keyMaster } from '@/config/constants';
import { showMessage } from '@/utils/messages';
import { InitForm, handleMapData } from './index';
import { InputText, InputCheckBox } from '@/components/Input';
import { validateAllField, validateField } from '@/utils/validate';
import { state } from '@/state/modules/authfack';

export default {
    page: {
        title: '賞品戻り新規登録',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Layout,
        Footer,
        Multiselect,
        DateNormal,
        InputText,
        PickerDate,
        InputCheckBox
    },
    data() {
        return {
            loading: false,
            id: this.$route.query.id,
            listDataMasterCampaign: [],
            listDataMasterCompare: [],
            form: InitForm(),
            userInfo: state?.user ?? null
        };
    },
    computed: {
        ...masterComputed,
        ...authComputed
    },

    mounted() {
        this.getFormattedDate();
        this.getListEventPrizeReturn();
        this.getListMasterCompare();
    },
    methods: {
        checkPermission,
        ...masterMethods,
        ...clientMethods,
        ...campaignMethods,
        ...prizeMethods,

        getFormattedDate() {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');

            this.form.return_date = `${year}-${month}-${day}`;
        },

        async getListEventPrizeReturn() {
            this.loading = true;
            try {
                const dataRes = await this.listMaster('all/campaign/event-prize');
                this.listDataMasterCampaign = dataRes ?? [];
                if (this.userInfo?.default_campaign?.id && dataRes.length > 0) {
                    this.form.campaign = dataRes.find((res) => res.id == this.userInfo.default_campaign.id);
                }
            } catch (error) {
                console.log('error', error);
            } finally {
                this.loading = false;
            }
        },

        async getListMasterCompare() {
            this.loading = true;
            try {
                const data = await this.listMaster(keyMaster['prize.corresponding_status']);
                this.listDataMasterCompare = data;
            } catch (error) {
                console.log('error', error);
            } finally {
                this.loading = false;
            }
        },

        formSubmit() {
            let checkAll = validateAllField(this.form);
            if (!checkAll) return;
            let dataObject = handleMapData(this.form);
            this.registerPrizeReturn(dataObject).then((data) => {
                if (data.code == 200) {
                    this.$router.push({ path: '/prize-return/list' }).then(() => {
                        showMessage(data.code, this.$bvToast, '賞品戻りが登録されました。');
                    });
                } else {
                    showMessage(data.code, this.$bvToast, data.message);
                }
            });
        },

        handleFieldCampaign(typeHandle) {
            this.form.event_prize = null;
            if (typeHandle == 'remove') {
                validateField(this.form.configFieldSelectCampaign);
            }
        },

        handleValidateField(configField) {
            validateField(configField);
        },

        hasReturnStatus(param) {
            return param.return_status?.value === '確認中' || param.return_status?.value === '保管' ? false : true;
        },

        handleRemoveReturnStatus(obj) {
            validateField(obj.configFieldSelectReturnStatus);
            obj.reconfirmation_schedule = null;
        }
    }
};
</script>

<template>
    <Layout>
        <div v-if="!loading">
            <div class="col-lg-12">
                <div class="card form--sroll mb-0">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-1"></div>
                            <div class="col-sm-10">
                                <form class="needs-validation" @submit.prevent="formSubmit" autocomplete="off">
                                    <div class="row">
                                        <div class="col-sm-2">
                                            <div>
                                                <label for=""><small class="text-gray">キャンペーン情報</small></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-10">
                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05"
                                                    >{{ $t('pageReturnPrize.nameCampaign') }} <span class="text-danger">*</span></label
                                                >
                                                <div class="col-sm-9">
                                                    <div>
                                                        <Multiselect
                                                            :id="`select-campaign-object`"
                                                            :value.sync="form.campaign"
                                                            :options="listDataMasterCampaign"
                                                            :config.sync="form.configFieldSelectCampaign"
                                                            @select="handleFieldCampaign()"
                                                            @remove="handleFieldCampaign('remove')"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-2">
                                            <div>
                                                <label for=""><small class="text-gray">賞品情報</small></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-10">
                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05"
                                                    >{{ $t('pageReturnPrize.namePrize') }} <span class="text-danger">*</span></label
                                                >
                                                <div class="col-sm-9">
                                                    <Multiselect
                                                        :id="`select-event_prize-object`"
                                                        :value.sync="form.event_prize"
                                                        :options="form.campaign?.event_prizes ?? []"
                                                        :config.sync="form.configFieldSelectPrize"
                                                        @remove="handleValidateField(form.configFieldSelectPrize)"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-2">
                                            <div>
                                                <label for=""><small class="text-gray">運送会社情報</small></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-10">
                                            <div
                                                class="form-group row"
                                                v-if="$route.query.id && form.campaign && form.campaign.partners && form.campaign.partners.length > 0"
                                            >
                                                <label class="col-sm-3" for="validationCustom05"></label>
                                                <div class="col-sm-9">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th>対応パートナー</th>
                                                                <th>パートナー担当</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in form.campaign.partners" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>{{ item.partner_pic_name }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05"
                                                    >{{ $t('pageReturnPrize.returnDate') }} <span class="text-danger">*</span></label
                                                >
                                                <div class="col-sm-8">
                                                    <DateNormal
                                                        :year.sync="form.return_date_y"
                                                        :month.sync="form.return_date_m"
                                                        :day.sync="form.return_date_d"
                                                        :full_date.sync="form.return_date"
                                                        :config.sync="form.configFieldReturnDate"
                                                        @blur="handleValidateField(form.configFieldReturnDate)"
                                                    >
                                                        <div v-if="form.configFieldReturnDate.error" class="invalid-feedback d-block">
                                                            <span>{{ form.configFieldReturnDate.errorText }}</span>
                                                        </div>
                                                    </DateNormal>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{ $t('pageReturnPrize.province') }}</label>
                                                <div class="col-sm-9">
                                                    <InputText
                                                        :id="`province`"
                                                        :model.sync="form.province"
                                                        :config.sync="form.configFieldProvince"
                                                        @blur="handleValidateField(form.configFieldProvince)"
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{ $t('pageReturnPrize.shippingReturnFee') }}</label>
                                                <div class="col-sm-9">
                                                    <InputText
                                                        :id="`shipping_return_fee`"
                                                        :model.sync="form.shipping_return_fee"
                                                        :config.sync="form.configFieldShippingReturnFee"
                                                        @blur="handleValidateField(form.configFieldShippingReturnFee)"
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{ $t('pageReturnPrize.shippingCompany') }} </label>
                                                <div class="col-sm-9">
                                                    <InputText
                                                        :id="`shipping_company`"
                                                        :model.sync="form.shipping_company"
                                                        :config.sync="form.configFieldShipping"
                                                        @blur="handleValidateField(form.configFieldShipping)"
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{
                                                    $t('pageReturnPrize.contactInformationNumber')
                                                }}</label>
                                                <div class="col-sm-9">
                                                    <InputText
                                                        :id="`contact_information_number`"
                                                        :model.sync="form.contact_information_number"
                                                        :config.sync="form.configFieldContactInfo"
                                                        @blur="handleValidateField(form.configFieldContactInfo)"
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05"
                                                    >{{ $t('pageReturnPrize.reason') }} <span class="text-danger">*</span></label
                                                >
                                                <div class="col-sm-9">
                                                    <InputText
                                                        :id="`reason`"
                                                        :model.sync="form.reason"
                                                        :config.sync="form.configFieldReason"
                                                        @blur="handleValidateField(form.configFieldReason)"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-2">
                                            <div>
                                                <label for=""><small class="text-gray">ステータス</small></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-10">
                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{ $t('pageReturnPrize.flagFLG') }}</label>
                                                <div class="col-sm-9">
                                                    <div>
                                                        <InputCheckBox
                                                            class="custom-switch"
                                                            :model.sync="form.end_flag"
                                                            :id="`__BVID__742`"
                                                            :value="true"
                                                            :label="``"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05"
                                                    >{{ $t('pageReturnPrize.returnStatus') }} <span class="text-danger">*</span></label
                                                >
                                                <div class="col-sm-9">
                                                    <Multiselect
                                                        :id="`select-return_status-object`"
                                                        :value.sync="form.return_status"
                                                        :options="listDataMasterCompare"
                                                        :config.sync="form.configFieldSelectReturnStatus"
                                                        @select="handleRemoveReturnStatus(form)"
                                                        @remove="handleRemoveReturnStatus(form)"
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{ $t('pageReturnPrize.instruction') }}</label>
                                                <div class="col-sm-9">
                                                    <InputText
                                                        :id="`instruction`"
                                                        :model.sync="form.instruction"
                                                        :config.sync="form.configFieldInstruction"
                                                        @blur="handleValidateField(form.configFieldInstruction)"
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05"
                                                    >{{ $t('pageReturnPrize.reconfirmationSchedule') }}
                                                </label>
                                                <div class="col-sm-3">
                                                    <div style="min-width: 180px">
                                                        <PickerDate
                                                            :id="`reconfirmationDate`"
                                                            :model.sync="form.reconfirmation_schedule"
                                                            :config.sync="form.configFieldReconfirmation"
                                                            :disabled="hasReturnStatus(form)"
                                                            @remove="handleValidateField(form.configFieldReconfirmation)"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{ $t('pageReturnPrize.compatiableWithJp') }}</label>
                                                <div class="col-sm-9">
                                                    <InputText
                                                        :id="`compatiable_with_jp_logistic_development`"
                                                        :model.sync="form.compatiable_with_jp_logistic_development"
                                                        :config.sync="form.configFieldLogictis"
                                                        @blur="handleValidateField(form.configFieldLogictis)"
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{ $t('pageReturnPrize.storageCode') }}</label>
                                                <div class="col-sm-9">
                                                    <InputText
                                                        :id="`storage_code`"
                                                        :model.sync="form.storage_code"
                                                        :config.sync="form.configFieldStorageCode"
                                                        @blur="handleValidateField(form.configFieldStorageCode)"
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{ $t('pageReturnPrize.trackingNumber') }}</label>
                                                <div class="col-sm-9">
                                                    <InputText
                                                        :id="`tracking_number`"
                                                        :model.sync="form.tracking_number"
                                                        :config.sync="form.configFieldTrackingNumber"
                                                        @blur="handleValidateField(form.configFieldTrackingNumber)"
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{ $t('pageReturnPrize.note') }}</label>
                                                <div class="col-sm-9">
                                                    <InputText
                                                        :id="`note`"
                                                        :model.sync="form.note"
                                                        :config.sync="form.configFieldNote"
                                                        @blur="handleValidateField(form.configFieldNote)"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill">
                        <button
                            type="button"
                            class="btn btn-light mr-3"
                            @click="
                                $router.push({
                                    path: '/prize-return/list'
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            type="submit"
                            class="btn btn-primary"
                            v-if="checkPermission('return_prize.register') || (checkPermission('return_prize.edit') && $route.query.id)"
                            @click="formSubmit"
                        >
                            {{ $route.query.id ? $t('btn.save') : $t('btn.register') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
